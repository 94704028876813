<template>
  <transition name="fade-in">
    <div
      v-if="isOpen"
      :key="wrapperKey"
      class="app-filter-popup"
    >
      <div class="app-filter-popup__header">
        <AppIcon
          icon="close-background"
          :is-raw="true"
          class="app-filter-popup__close"
          @click="closePopup()"
        />

        <div
          class="app-filter-popup__reset"
          @click="resetSelection"
          @keypress="resetSelection"
        >
          {{ useTranslation('general', 'reset') }}
        </div>
      </div>

      <div class="app-filter-popup__label">
        {{ label }}
      </div>

      <ul class="app-filter-popup__entries">
        <li
          v-for="option of options"
          :key="option._uid"
          :class="{ checked: selectedValues?.includes(option.value) }"
          @click="handleSelection(option.value)"
          @keypress="handleSelection(option.value)"
        >
          <span class="form-select__option-background" />

          {{ option.value }}

          <span class="app-filter-popup__option-checked ">
            <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M3.0899 7.2279L0 4.138L0.59315 3.54485L3.0899 6.0416L8.38687 0.744629L8.98002 1.33778L3.0899 7.2279Z"
                fill="white"
              />
            </svg>
          </span>
        </li>
      </ul>

      <div class="app-filter-popup__footer">
        <AtomButton
          :text="useTranslation('general', 'show_results')"
          @click="closePopup()"
        />
      </div>
    </div>
  </transition>
</template>

<script setup>
defineProps({
    isOpen: {
        type: Boolean,
        default: false,
    },
    label: {
        type: String,
        default: '',
    },

    options: {
        type: Array,
        default: () => [],
    },

    selectedValues: {
        type: Array,
        default: () => [],
    },

    wrapperKey: {
        type: String,
        default: '',
    },
});

const emit = defineEmits('emit-values', 'emit-open', 'emit-reset');

const closePopup = () => {
    emit('emit-open', true);
};

const handleSelection = (value) => {
    emit('emit-values', value);
};

const resetSelection = () => {
    emit('emit-reset');
};
</script>

<style lang="scss" scoped>
.app-filter-popup {
    position: fixed;
    z-index: 200;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 100vh;
    height: 100dvh;
    padding-right: 20px;
    padding-bottom: 100px;
    padding-left: 20px;
    background: $C_WHITE;
    pointer-events: all;
}

.app-filter-popup__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 27px 25px;
    padding-right: 0;
    padding-bottom: 0;
    margin-bottom: 31px;
}

.app-filter-popup__reset {
    @include typo-font('regular');
    color: $C_PRIMARY;
    cursor: pointer;
    font-size: 16px;
}

.app-filter-popup__close {
    cursor: pointer;
}
.app-filter-popup__label {
    @include typo-font('regular');
    @include typo-size('h3');
    position: relative;

    padding: 27px 25px;
    padding-top: 0;
}

.app-filter-popup__footer {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100px;
    align-items: center;
    justify-content: center;
    background: $C_GREY_LIGHT;
}

.app-filter-popup__entries {
    position: absolute;
    top: 140px;
    width: calc(100% - 20px - 20px);
    height: calc(100vh - 100px - 150px);
    height: calc(100dvh - 100px - 150px);

    overflow-y: scroll;
    @include remove-scrollbars();

    li {
        @include typo-font('regular');
        position: relative;
        padding: 18px 25px;
        cursor: pointer;
        font-size: 16px;
        transition: padding 0.2s ease-in-out;

        &.checked {
            padding-left: 40px;
        }

        .is-full-screen & {
            &.checked {
                padding-left: 25px;
            }
        }

        &:after {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2px;
            border-radius: 5px;
            background: $C_GREY_DARK;
            content: '';
            opacity: 0.1;
        }

        &:last-child {
            margin-bottom: 15px;

        }

        &:first-child {
            margin-top: 10px;
            &:before {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 2px;
                border-radius: 5px;
                background: $C_GREY_DARK;
                content: '';
                opacity: 0.1;
            }
        }
    }
}

.app-filter-popup__option-background {
    position: absolute;
    z-index: -1;
    top: 7px;
    right: 7px;
    bottom: 7px;
    left: 7px;
    border-radius: 6px;
    background: $C_GREEN__HERO;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s ease-in-out;

    .is-full-screen & {
        background: $C_WHITE;
    }

    .checked & {
        opacity: 1;
    }
}

.app-filter-popup__option-checked {
    position: absolute;
    top: 0;
    right: 30px;
    bottom: 0;
    display: flex;
    width: 19px;
    height: 19px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: auto;
    background: transparent;
    transform: scale(0.8);
    transition: background-color 0.30s $EASE_OUT--CIRC, transform 0.35s $EASE_OUT--BACK;

    svg {
        transform: scale(0);
        transition: transform 0.35s $EASE_OUT--CIRC;
    }

    .checked & {
        background: $C_PRIMARY;
        transform: scale(1);

        svg {
            transform: scale(1);
        }
    }
}
</style>
